import type {
  ApolloConfig,
  DatadogConfig,
  EnvironmentConfig,
  MediaConfig,
} from "ui-kit";
import {
  ApolloSchema,
  DatadogSchema,
  EnvironmentSchema,
  getApolloConfig,
  getDatadogConfig,
  getEnvironmentConfig,
  getMediaConfig,
  MediaSchema,
  validateConfig,
  Yup,
} from "ui-kit";

type AppConfig = EnvironmentConfig &
  ApolloConfig &
  MediaConfig &
  DatadogConfig & {
    liffUrl: string;
  };

const schema: Yup.SchemaOf<AppConfig> = Yup.object()
  .concat(EnvironmentSchema)
  .concat(ApolloSchema)
  .concat(MediaSchema)
  .concat(DatadogSchema)
  .concat(
    Yup.object({
      liffUrl: Yup.string().required(),
    })
  );

const envConfig = getEnvironmentConfig(process.env);

const kioskConfig = {
  liffUrl: process.env.REACT_APP_LIFF_URL,
};

export default validateConfig<AppConfig>(
  {
    name: "Kiosk App",
    env: process.env,
    config: {
      ...envConfig,
      ...getApolloConfig(process.env),
      ...getMediaConfig(process.env),
      ...getDatadogConfig(process.env),
      ...kioskConfig,
    },
    schema,
  },
  {
    log: envConfig.DEBUG,
  }
);
