import type { RouteType } from "ui-kit";
import { NotFoundPage, React, Redirect } from "ui-kit";

import { assetsRoutes } from "./assets";

const getApplicationRoutes = (routesConfig: RouteType[]): RouteType[] => {
  const definedRoutes = routesConfig.map((route) => ({
    ...route,
    exact: route?.exact || true,
  }));

  return [
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/assets" />,
    },
    ...definedRoutes,
    {
      path: "*",
      component: () => <NotFoundPage />,
    },
  ];
};

const applicationRoutes: RouteType[] = [...assetsRoutes];

export default getApplicationRoutes(applicationRoutes);
