import "./index.css";

import { bootstrapApp, ErrorScreen } from "ui-kit";

import App from "./App";
import config from "./config";
import en from "./i18n/en.json";
import newKeys from "./i18n/new-keys.json";

const {
  DEBUG,
  DATADOG_IS_ENABLED,
  DATADOG_SERVICE_NAME,
  DATADOG_CLIENT_TOKEN,
} = config;

bootstrapApp({
  App,
  ErrorScreen,
  messages: { en, newKeys },
  querySelector: "#root",
  DEBUG,
  datadogLoggingOptions: {
    enabled: DATADOG_IS_ENABLED,
    service: DATADOG_SERVICE_NAME,
    clientToken: DATADOG_CLIENT_TOKEN,
  },
});
