import type { RouteType } from "ui-kit";
import { lazy } from "ui-kit";

export const assetsRoutes: RouteType[] = [
  {
    path: "/assets",
    component: lazy(() => import("pages/assets/List")),
  },
  {
    path: "/assets/:assetId",
    component: lazy(() => import("pages/assets/View")),
  },
];
