import { ApolloProvider, getApolloClient, React, RenderRoutes } from "ui-kit";

import config from "./config";
import routes from "./routing";

const { DEBUG, GRAPHQL_URL } = config;

export default function App() {
  return (
    <ApolloProvider
      client={getApolloClient({
        token: "",
        uri: GRAPHQL_URL,
        connectToDevTools: DEBUG,
        name: "kiosk",
      })}
    >
      <RenderRoutes routes={routes} />
    </ApolloProvider>
  );
}
